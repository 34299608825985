/* global JQuery */
import type { ajax } from "jquery";
import { TooltipElement } from "./types/tooltip-element.d";
import { TooltipTrigger } from "./types/tooltip-trigger.d";

/** Map of elements registered via `XF.Element.register` by their indirect key (e.g. `data-xf-init="auto-complete"`) and actual key (e.g. `XF.AutoComplete`) */
type XFRegisteredElementsMap = {
  "add-user": "AddForm";
  "admin-nav": "AdminNav";
  "admin-search": "AdminSearch";
  "ajax-submit": "AjaxSubmit";
  "attachment-manager": "AttachmentManager";
  "attachment-on-insert": "AttachmentOnInsert";
  "auto-complete": "AutoComplete";
  "auto-submit": "AutoSubmit";
  "auto-timezone": "AutoTimeZone";
  "avatar-cropper": "AvatarCropper";
  "avatar-upload": "AvatarUpload";
  "bookmark-click": "BookmarkClick";
  "bookmark-label-filter": "BookmarkLabelFilter";
  "braintree-apply-pay-form": "BraintreeApplePayForm";
  "braintree-payment-form": "BraintreePaymentForm";
  "braintree-paypal-form": "BraintreePayPalForm";
  carousel: "Carousel";
  "changed-field-notifier": "ChangedFieldNotifier";
  "check-all": "CheckAll";
  "checkbox-select-disabler": "CheckboxSelectDisabler";
  "code-block": "CodeBlock";
  "code-editor-switcher-container": "CodeEditorSwitcherContainer";
  "code-editor": "CodeEditor";
  "color-picker": "ColorPicker";
  "copy-to-clipboard": "CopyToClipboard";
  "date-input": "DateInput";
  "desc-loader": "DescLoader";
  disabler: "Disabler";
  draft: "Draft";
  "editor-manager": "EditorManager";
  editor: "Editor";
  "element-tooltip": "ElementTooltip";
  "emoji-completer": "EmojiCompleter";
  "field-adder": "FieldAdder";
  filter: "Filter";
  "focus-inserter": "InserterFocus";
  "focus-trigger": "FocusTrigger";
  "form-fill": "FormFill";
  "form-submit-row": "FormSubmitRow";
  "guest-captcha": "GuestCaptcha";
  "guest-username": "GuestUsername";
  "h-scroller": "HScroller";
  "inline-mod": "InlineMod";
  "key-captcha": "KeyCaptcha";
  lightbox: "Lightbox";
  "list-sorter": "ListSorter";
  "login-form": "LoginForm";
  "member-tooltip": "MemberTooltip";
  "min-length": "MinLength";
  "multi-quote": "MultiQuote";
  nestable: "Nestable";
  notices: "Notices";
  "number-box": "NumberBox";
  oembed: "OembedFetcher";
  "page-jump": "PageJump";
  "password-hide-show": "PasswordHideShow";
  "password-strength": "PasswordStrength";
  "payment-provider-container": "PaymentProviderContainer";
  "permission-choice": "PermissionChoice";
  "permission-form": "PermissionForm";
  "permission-matrix": "PermissionMatrix";
  "poll-block": "PollBlock";
  "post-edit": "PostEdit";
  "prefix-loader": "PrefixLoader";
  "prefix-menu": "PrefixMenu";
  "preview-click": "PreviewClick";
  "preview-tooltip": "PreviewTooltip";
  preview: "Preview";
  "push-cta": "PushCta";
  "push-toggle": "PushToggle";
  "qa-captcha": "QaCaptcha";
  "quick-edit": "QuickEdit";
  "quick-reply": "QuickReply";
  "quick-search": "QuickSearch";
  "quick-thread": "QuickThread";
  rating: "Rating";
  "re-captcha": "ReCaptcha";
  reaction: "Reaction";
  "reg-form": "RegForm";
  "responsive-data-list": "ResponsiveDataList";
  "select-plus": "SelectPlus";
  "select-to-quote": "SelectToQuote";
  "share-buttons": "ShareButtons";
  "share-input": "ShareInput";
  "share-tooltip": "ShareTooltip";
  "solve-captcha": "SolveCaptcha";
  stats: "Stats";
  "sticky-header": "StickyHeader";
  sticky: "Sticky";
  "stripe-payment-form": "StripePaymentForm";
  tabs: "Tabs";
  tagger: "Tagger";
  "textarea-handler": "TextAreaHandler";
  "thread-edit-form": "ThreadEditForm";
  "toggle-storage": "ToggleStorage";
  "token-input": "TokenInput";
  tooltip: "Tooltip";
  "touch-proxy": "TouchProxy";
  "translate-submit": "TranslateSubmit";
  tweet: "TweetRenderer";
  "user-mentioner": "UserMentioner";
  "video-init": "VideoInit";
  "video-player": "VideoPlayer";
};
type BaseXFElement = {
  $target: JQuery;
  options: NonNullable<unknown>;
  init(): void;
  getOption(option: string): unknown;
};
type XFRegisteredElementsKeys =
  XFRegisteredElementsMap[keyof XFRegisteredElementsMap];
type XFRegisteredElements = {
  [Property in XFRegisteredElementsKeys]: BaseXFElement;
};

interface XF extends XFRegisteredElements {
  /**
   * creates a new class using `props` as a prototype and `props.__construct` as a proxied constructor
   *
   * @deprecated prefer actual ES6 classes
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  create<ConstructorArgs extends unknown[], T extends object>(
    props: T & {
      __construct: (...args: ConstructorArgs) => void;
    }
  ): new (...args: ConstructorArgs) => T;

  /**
   * creates a child class of a parent class created via `XF.create`.
   * the parent's prototype is used as a prototype,
   * `extension.__backup` maps original members to new names on the prototype,
   * `extension.__backup` is itself deleted,
   * and other members of `extension` are copied to the prototype, potentially overriding the parent members
   *
   * note: the type signature here is only approximated, it would involve a significantly more complex set of generics to accurately model
   *
   * @deprecated prefer actual ES6 classes
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  extend<ConstructorArgs extends unknown[], Parent, Extension>(
    parent: Parent,
    extension: Extension & {
      __backup: Record<keyof Parent, string>;
    }
  ): new (...args: ConstructorArgs) => Parent & Extension;

  /**
   * dynamic js + css loader
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  Loader: {
    load(js: string[], css: string[], onComplete: () => void): void;
    /** shorthand for `Loader.load([], css, onComplete)` */
    loadCss(
      css: Parameters<XF["Loader"]["load"]>[0],
      onComplete: Parameters<XF["Loader"]["load"]>[2]
    ): void;
    /** shorthand for `Loader.load(js, [], onComplete)` */
    loadJs(
      js: Parameters<XF["Loader"]["load"]>[1],
      onComplete: Parameters<XF["Loader"]["load"]>[2]
    ): void;
  };

  /**
   * Given an array of URLs, load them all (if not already loaded)
   * before running a callback function on complete (success or error).
   *
   * In the absolute majority of browsers, this will execute the loaded scripts in the order provided.
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  loadScripts(urls: string[], completeCallback: () => void): void;

  /**
   * @param $form form to get form data from
   * @param $submitButton if provided, button's name + value are added to the form data as another field
   * @param jsonName name of extra field aggregating elements matching `jsonOptIn`
   * @param jsonOptIn field names to convert to json elements grouped under `jsonName`
   *
   * @returns FormData, or an array of objects describing each field if json parameters are used
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  getDefaultFormData($form: JQuery, $submitButton?: JQuery | null): FormData;
  getDefaultFormData(
    $form: JQuery,
    $submitButton: JQuery | null,
    jsonName: string | null,
    jsonOptIn: string | string[] | null
  ): { name: string; value: string }[];

  /**
   * switch handler
   *
   * reference:
   * [action.js](../../../xf/core/action.js)
   */
  handleSwitchResponse(
    $target: JQuery,
    data: {
      switchKey?: string;
      redirect?: string;
      message?: string;
      text?: string;
      addClass?: string;
      removeClass?: string;
    },
    allowRedirect?: boolean
  ): void;

  /**
   * replaces editor content with provided html/text
   *
   * @returns whether any editor was affected
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  replaceEditorContent(
    $container: JQuery,
    html: string,
    text: string,
    notConstraints: Parameters<JQuery["not"]>[0]
  ): boolean;

  /**
   * @returns a canonical url for the provided url or url fragment. note that:
   * - relative paths are not respected (e.g. `'../foo' -> 'https://site.com/../foo'`)
   * - strings starting with a letters and a colon will not be modified (e.g. `'public:foo/bar' -> 'public:foo/bar'`)
   * - urls starting with a non-canonical variation of actual site's base url will not be modified (e.g. `'http://SiTe.CoM/foo' -> 'http://SiTe.CoM/foo'`)
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  canonicalizeUrl(url: string): string;

  /**
   * value of `Date.now()` when page was displayed
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  pageDisplayTime: number | null;

  /**
   * @returns an estimation of the time when the the page load started in seconds
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  getLocalLoadTime(): number;

  /**
   * @returns whether the event came from a touch type pointer
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  isEventTouchTriggered(event: JQuery.Event): boolean;

  /**
   * @returns whether the device supports pointer events
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  supportsPointerEvents(): boolean;

  /**
   * push notification handler
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  Push: {
    serviceWorkerReg: ServiceWorkerRegistration | null;
    isSubscribed: boolean | null;
    initialize(): void;
    registerWorkeron(RegisterSuccess: unknown, onRegisterError: unknown): void;
    getPushHistoryUserIds(): unknown;
    setPushHistoryUserIds(userIds: unknown): void;
    hasUserPreviouslySubscribed(userId: unknown): boolean;
    addUserToPushHistory(userId: unknown): void;
    removeUserFromPushHistory(userId: unknown): void;
    handleUnsubscribeAction(
      onUnsubscribe: unknown,
      onUnsubscribeError: unknown
    ): void;
    handleSubscribeAction(
      suppressNotification: unknown,
      onSubscribe: unknown,
      onSubscribeError: unknown
    ): void;
    handleToggleAction(
      onUnsubscribe: unknown,
      onUnsubscribeError: unknown,
      onSubscribe: unknown,
      onSubscribeError: unknown
    ): void;
    updateUserSubscription(subscription: unknown, type: unknown): void;
    isSupported(): boolean;
    base64ToUint8(base64String: unknown): Uint8Array;
    isExpectedServerKey(input: unknown): boolean;
  };

  /**
   * reference:
   * [structure.js](../../../xf/core/structure.js)
   */
  MenuWatcher: {
    onOpen($menu: JQuery, touchTriggered?: boolean): void;
    onClose($menu: JQuery): void;
    closeAll(): void;
    closeUnrelated(el: string): void;
    preventDocClick(): void;
    allowDocClick(): void;
  };

  /**
   * feature detection
   *
   * reference:
   * [preamble.ts](./preamble.ts)
   */
  Feature: {
    init(): void;
    has(name: string): unknown;
    hasPassiveeventlisteners(): boolean;
    hasTouchevents(): string | boolean;
    hasHiddenscroll(): boolean;
  };

  /**
   * helpers for retrieving/modifying localStorage
   *
   * note: may use a cookie as a fallback
   *
   * reference: [core.js](../../../xf/core.js)
   */
  LocalStorage: {
    /** @returns key with cookie prefix */
    getKeyName(name: string): string;
    /** @returns localStorage value, using fallback value if it can't be found */
    get(name: string): string | null;
    /** @returns same as {@link XF.LocalStorage.get} but also parses JSON. if value is not found or value cannot be parsed as JSON, returns `{}` */
    getJson<T = unknown>(name: string): T;
    /**
     * sets localStorage value
     *
     * @param allowFallback if `true` **and** the original `set` operation fails, updates fallback value instead
     */
    set(name: string, value: string, allowFallback?: boolean): void;
    /** same as {@link XF.LocalStorage.set} but also stringifies JSON */
    setJson(name: string, value: unknown, allowFallback?: boolean): void;
    /** removes item from localStorage and fallback */
    remove(name: string): void;
    /** @returns fallback via {@link XF.Cookie} for `ls` */
    getFallbackValue(): void;
    /** sets fallback via {@link XF.Cookie} for `ls` */
    updateFallbackValue(newValue: unknown): void;
  };

  /**
   * various XF setup for dynamically inserted HTML, e.g. `data-xf-init`
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  activate(el: JQuery | Element): void;

  /**
   * generic loading state (indeterminate bar along the top border and throbber in the top right)
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  ActionIndicator: {
    show(): void;
    hide(): void;
  };

  AjaxSubmit: BaseXFElement & {
    init(): void;
    /**
     * enables all buttons within a form after ajax response
     *
     * reference:
     * [form.js](../../../xf/form.js)
     */
    enableButtons(): void;
  };

  /** reference: [preamble.ts](../preamble.ts) */
  browserOriginal?: {
    [browser: string]: string | number | boolean | null | undefined;
    browser: string;
    version?: number;
    osVersion?: number | null;
  };

  /**
   * displays a temporary message at the top of the screen for 3000ms
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  toast(message: string, onClose?: () => void): void;

  /**
   * Focuses on the textarea in the XF editor
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  focusEditor(container: JQuery | Element, notConstraints?: unknown): void;

  /**
   * @returns a copy of the function at `object[functionKey]` with `this` bound to `object`.
   *
   * Note: this function also has an overload in the format `proxy(function () { ... }, this)`.
   * This overload is not included in the type signature as it is never used in our TS.
   *
   * @deprecated This is an overly complex and unnecessary abstraction.
   * Prefer native solutions for issues with `this` scope, e.g. arrow functions or `bind`/`apply`/`call`.
   */
  proxy<T, K extends keyof T>(object: T, functionKey: K | string): T[K];

  /**
   * stub type
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  Overlay: {
    options: {
      backdropClose: boolean;
      escapeClose: boolean;
      focusShow: boolean;
      className: string;
    };
    show(): void;
    hide(): void;
  };

  /**
   * hides the closest `.overlay-container[data-overlay]` ancestor of `$child`
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  hideParentOverlay($child: JQuery): void;
  /**
   * creates (but does not show) an overlay with the provided `content`
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  getOverlayHtml(
    content:
      | string
      | JQuery
      | {
          html: string | JQuery;
          dismissible?: boolean;
          title?: string;
        }
  ): JQuery;
  /**
   * creates and shows an instance of {@link XF.Overlay}.
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  showOverlay(
    $html: JQuery,
    options?: Partial<XF["Overlay"]["options"]>
  ): XF["Overlay"];
  /**
   * creates and shows an overlay.
   * uses {@link XF.showOverlay} internally.
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  overlayMessage(
    title: string,
    contentHtml: string | JQuery
  ): ReturnType<XF["showOverlay"]>;
  /**
   * creates and shows an overlay, with error-related defaults and basic object serialization.
   * uses {@link XF.overlayMessage} internally.
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  alert(
    message: string | { [key: string]: { toString(): string } },
    /** affects the default `title` (but `title` is currently hidden via CSS) */
    messageType?: "error" | "",
    /** title above alert message (currently hidden via CSS) */
    title?: string
  ): ReturnType<XF["overlayMessage"]>;

  /**
   * @returns the source `string`, with keys in the `pairs` map substituted with the associated value.
   *
   * note:
   * - the substitution is a simple regex replace, not an actual i18n syntax
   * - the name is misleading: this function does not perform any translation
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  stringTranslate(
    string: string,
    pairs: { [key: string]: string | number }
  ): string;
  /**
   * @returns the phrase value for the provided key `name`
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  phrase(
    name: Parameters<XF["stringTranslate"]>[0],
    /** if included, performs a string substitution using {@link XF.stringTranslate} */
    vars?: Parameters<XF["stringTranslate"]>[1],
    fallback?: string
  ): string;

  /** @returns `true` if `html[dir="rtl"]` */
  isRtl(): boolean;
  /**
   * If `keyword` is `"left"` or `"right"` and the document is in RTL mode,
   * returns the opposite string (in lowercase). Otherwise, returns `keyword`.
   */
  rtlFlipKeyword(keyword: string): string;

  /**
   * `XF.findRelativeIf()` looks at the first character of the passed selector:
   * - If it is a `|`, then it is stripped from the selector and the selector is passed to `find()` (`|` is basically synonymous with "find relative to the current base element").
   * - If it is a `>`, then the selector is simply passed to `find()` as-is (since `find()` already supports selecting immediate children and their descendants).
   * - If it is a `<`, then the selector is passed to `findExtended()`, which is a custom jQuery plugin that does the heavy lifting for finding parents and siblings and their descendants by way of changing the base element prior to running `find()`.
   * - If it is none of `|`, `>`, or `<`, then it is passed as an ordinary jQuery selector (`$(...)`).
   *
   * references:
   * [core.js](../../../xf/core.js),
   * [xf dev forum](https://xenforo.com/community/threads/using-ajax-with-the-post-macro.135226/post-1259006)
   */
  findRelativeIf(selector: string, $base: JQuery): JQuery;

  /**
   * @returns whether the device is IOS based on the user-agent string
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  isIOS(): boolean;

  /**
   * helpers for retrieving/modifying cookies
   *
   * reference: [core.js](../../../xf/core.js)
   */
  Cookie: {
    /** @returns XF cookie with `name` (decodes URI internally) */
    get(name: string): string | null;
    /** sets XF cookie with `name` (encodes URI internally) */
    set(name: string, value: string | number | boolean, expires?: Date): void;
    /** @returns same as {@link XF.Cookie.get} but also parses JSON. if value is not found or value cannot be parsed as JSON, returns `{}` */
    getJson<T = unknown>(name: string): T;
    /** same as {@link XF.Cookie.set} but also stringifies JSON */
    setJson(name: string, value: unknown, expires?: Date): void;
    /** sets XF cookie with `name` to empty + expired */
    remove(name: string): unknown;
  };

  /**
   * various globals
   *
   * references:
   * [core.js](../../../xf/core.js),
   * [helper_js_global.html](../../../../src/styles/California/templates/public/helper_js_global.html)
   */
  config: Readonly<{
    /** ID of the current user */
    userId: number;
    enablePush: boolean;
    skipServiceWorkerRegistration: boolean;
    skipPushNotificationCta: boolean;
    pushAppServerKey: unknown;
    csrf: string;
    /** collection of values about the time. note that these values are static */
    time: Readonly<{
      /** time in seconds */
      now: number;
      /** time in seconds of start of current day */
      today: number;
      /** the day of the week (Monday = 0) */
      todayDow: number;
    }>;
    cookie: Readonly<{
      path: string;
      domain: string;
      prefix: string;
      secure: boolean;
    }>;
    url: Readonly<{
      /** e.g. `"https://xenforo.local.svc.cluster.local/"` */
      fullBase: string;
      /** e.g. `"/"` */
      basePath: string;
      css: string;
      keepAlive: string;
    }>;
    /** map of loaded CSS, e.g.
     * ```js
     * {
     *  'public:app.less': true,
     *  'public:core.less': true,
     * }
     * ``` */
    css: Readonly<{ [template: string]: true }>;
    /** map of loaded JS, e.g.
     * ```js
     * {
     *  '/js/california/dist/action-override.c2a60137e81089b338a0.js': true,
     *  '/js/california/dist/advanced-search-gtm.d8f6bcf2ab83d79749a7.js': true,
     * }
     * ``` */
    js: Readonly<{ [script: string]: true }>;
    jsState: unknown;
    /** map of common imperative animation durations in ms */
    speed: Readonly<{
      xxfast: number;
      xfast: number;
      fast: number;
      normal: number;
      slow: number;
    }>;
    job: Readonly<{ manualUrl: string }>;
    /** e.g. `"3px" */
    borderSizeFeature: string;
    /** from Templater.php */
    fontAwesomeWeight: "l" | "r" | "s";
    /** `enableReverseTabnabbingProtection` */
    enableRtnProtect: boolean;
    enableFormSubmitSticky: boolean;
    /** map of count properties for the current user */
    visitorCounts: Readonly<{
      conversations_unread: number | string;
      alerts_unread: number | string;
      total_unread: number | string;
      title_count: boolean;
      icon_indicator: boolean;
    }>;
    /** size in bytes */
    uploadMaxFilesize: number | null;
    /** size in MB */
    attachmentMaxPerMessage: number;
    /** comma-separated list */
    attachmentExtensions: string;
    allowedVideoExtensions: string[];
    shortcodeToEmoji: boolean;
    /** site logo as a square image */
    publicMetadataLogoUrl: string;
    /** notification bell image */
    publicPushBadgeUrl: string;
    rubiconAdOnly: boolean;
  }>;
  /**
   * AJAX helper
   *
   * uses jquery's ajax internally
   *
   * [reference](../../../xf/core.js)
   */
  ajax(
    method: "get" | "post",
    url: string,
    successCallback?: NonNullable<Parameters<typeof ajax>[0]>["success"]
  ): ReturnType<typeof ajax>;
  ajax(
    method: "get" | "post",
    url: string,
    data?: FormData | Record<string, unknown> | null,
    successCallback?: NonNullable<Parameters<typeof ajax>[0]>["success"] | null,
    options?: {
      /** if `true`, same as setting `{ skipDefaultSuccess: true, skipDefaultSuccessError: true }` */
      skipDefault?: boolean;
      skipDefaultSuccessError?: boolean;
      skipDefaultSuccess?: boolean;
      skipError?: boolean;
      /**
       * whether throbber will show
       * @default true
       */
      global?: boolean;
    }
  ): ReturnType<typeof ajax>;

  /**
   * This system allows elements to have a trigger event attached (eg: click, focus etc.),
   * such that the code for handling the event is only attached at the time that the event
   * is actually triggered, making for fast page initialization time.
   *
   * reference: [core.js](../../../xf/core.js)
   */
  Event: {
    watch(eventType: string): void;
    initElement(
      target: string | Element,
      eventType: string,
      e?: unknown
    ): unknown;
    getElementHandler(
      $el: JQuery,
      handlerName: string,
      eventType: string
    ): unknown;
    register(eventType: string, identifier: string, className: string): void;
    extend(identifier: string, extension: unknown): void;
    newHandler(
      extend: Parameters<XF["Event"]["extend"]>[1]
    ): ReturnType<XF["Event"]["extend"]>;
    AbstractHandler: unknown;
  };

  /**
   * This system allows elements with data-xf-init to be initialized at page load time
   *
   * reference: [core.js](../../../xf/core.js)
   */
  Element: {
    register(identifier: string, className: string): void;
    extend<Base extends keyof XFRegisteredElementsMap, Extension>(
      identifier: Base,
      extension: Extension &
        ThisType<(typeof XF)[XFRegisteredElementsMap[Base]] & Extension>
    ): void;
    initialize(root: string | Element | JQuery): void;
    // allows initializing element loaded dynamically
    initializeElement(el: Element | JQuery): void;
    applyHandler($el: JQuery, handlerId: string, options: unknown): unknown;
    getHandler($el: JQuery, handlerId: string): unknown;
    getHandlers(handlerId: string): unknown;
    newHandler(
      extend: Parameters<XF["Element"]["extend"]>[1]
    ): ReturnType<XF["Element"]["extend"]>;
    AbstractHandler: unknown;
  };

  /**
   * This system allows to edit, preview, and save messages
   *
   * reference: [message.js](../../../xf/core.js)
   */
  Message: {
    insertMessages(
      dataHtml: unknown,
      $container: string | JQuery,
      ascending?: boolean,
      onInsert?: unknown
    ): void;
  };

  /**
   * This system sets up the editor with configured options
   *
   * reference: [editor.js](../../../xf/core.js)
   */
  Editor: BaseXFElement & {
    options: {
      maxHeight: number;
      minHeight: number;
      buttonsRemove: string;
      attachmentTarget: boolean;
      deferred: boolean;
      attachmentUploader: string;
      attachmentContextInput: string;
    };
    getEditorConfig(): unknown;
    startInit(callbacks?: {
      beforeInit?: (editor: XF["Editor"], ed: XF["Editor"]["ed"]) => void;
      afterInit?: (editor: XF["Editor"], ed: XF["Editor"]["ed"]) => void;
    }): void;
    editorInit(): void;
    getHeightLimits(): (number | null)[];
    getButtonConfig(): unknown;
    scrollToCursor(): void;

    $form: JQuery | null;
    buttonManager: {
      getDropdown(name: string): string[];
    };
    ed: {
      $tb: JQuery;
      popups: {
        isVisible: (name: string) => boolean;
        get(names: string): JQuery;
        show: (name: string) => void;
        hide: (name: string) => void;
      };
      $oel: JQuery;
      image: {
        exitEdit: (arg0: boolean) => void;
        showInsertPopup: () => void;
        upload: (arg0: File[]) => void;
      };
      opts: {
        imageInsertButtons: string[];
        imageMaxSize: number;
        imageAllowedTypes: string[];
      };
    };
    mentioner: unknown;
    emojiCompleter: unknown;
    uploadUrl: string | null;
    usingStandardConfig: boolean;
  };

  /**
   * This system allows to show and handle interactions with the tooltip
   *
   * reference: [tooltip.js](../../../xf/core.js)
   */
  TooltipElement: new (
    ...args: ConstructorParameters<typeof TooltipElement>
  ) => TooltipElement;

  /**
   * This system allows to trigger showing the tooltip element and assign the tooltip target
   *
   * reference: [tooltip.js](../../../xf/core.js)
   */
  TooltipTrigger: new (
    ...args: ConstructorParameters<typeof TooltipTrigger>
  ) => TooltipTrigger;

  /**
   * constructs an html object from the controller view.html output
   * uses {@link XF.Loader.loadOverlay} internally.
   *
   * * @param container is an object of form {content, css, js}
   * reference:
   * [core.js](../../../xf/core.js)
   */
  setupHtmlInsert(
    container:
      | string
      | {
          content: string;
          css?: string[];
          js?: string[];
          cssInline?: string[];
          jsInline?: string[];
        }
      | JQuery,
    onReady: (
      $html: JQuery,
      container: HTMLElement,
      onComplete: (skipActivate: boolean | void) => void
    ) => boolean | void,
    retainScripts?: unknown
  ): void;

  /**
   * Clears the textarea of the XF editor
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  clearEditorContent($container: JQuery, notConstraints?: string): void;

  /**
   * Gets the container element for XF Editor
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  getEditorInContainer(
    $container: JQuery,
    notConstraints?: string
  ): XF["Editor"];

  /**
   * reloads the page on the given url
   * uses {@link XF.canonicalizeUrl} internally.
   *
   * * @param url string
   * reference:
   * [core.js](../../../xf/core.js)
   */
  redirect(url: string): boolean;

  QuickEditClick: {
    $target: JQuery;
    init: () => void;
    click: (e: Event) => void;
    stopEditing: (showMessage: boolean, onComplete: () => void) => void;
  };

  /**
   * @returns the max z-index of `$reference` and its parents
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  getElEffectiveZIndex($reference: JQuery): number;

  /**
   * offsets the z-index of each element in `$targets` based on the effective z-index of `$reference` and provided offset/minimum.
   * this will also store the original z-index under `data-base-z-index`
   *
   * reference:
   * [core.js](../../../xf/core.js)
   */
  setRelativeZIndex(
    $targets: JQuery,
    $reference: JQuery,
    offsetAmount?: number | null,
    minZIndex?: number
  ): void;

  OverlayClick: {
    loadUrl: string;
    show: (e: Event) => void;
  };

  hideOverlays(): void;
}

export const XF = (window.XF || {}) as XF;
window.XF = XF;
